import { lazy, Suspense, useState } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import useAuth from "./components/auth"
import Loading from "./components/loading"
import ProtectedRoute from "./components/protectedRoute"
import AccountLayout from "./routes/Account"

const Login = lazy(() => import("./routes/Login"))
const ForgotPassword = lazy(() => import("./routes/ForgotPassword"))
const ResetPassword = lazy(() => import("./routes/ResetPassword"))
const Register = lazy(() => import("./routes/Register"))
const Terms = lazy(() => import("./routes/Terms"))
const Privacy = lazy(() => import("./routes/Privacy"))
const Refund = lazy(() => import("./routes/Refund"))
const EmailVerify = lazy(() => import("./routes/EmailVerify"))
const Dashboard = lazy(() => import("./routes/Account/subroutes/dashboard"))
const Profile = lazy(() => import("./routes/Account/subroutes/profile"))
const Payment = lazy(() => import("./routes/Account/subroutes/payment"))
const SampleLayout = lazy(() =>
  import("./routes/Account/subroutes/sample/sampleLayout")
)
const SamplePaper = lazy(() =>
  import("./routes/Account/subroutes/sample/samplePaper")
)
// const Query = lazy(() => import("./routes/Account/subroutes/query/index"))
const Settings = lazy(() => import("./routes/Account/subroutes/settings/index"))

export default function App() {
  const {
    user,
    statusUpdater,
    profile,
    setProfile,
    fetchProfile,
    auth,
    logout,
    resetAuth
  } = useAuth()
  const [sampleLoaded, setSampleLoaded] = useState(false)

  return (
    <Router>
      <Routes>
        <Route element={<ProtectedRoute user={user} auth={auth} />}>
          <Route
            element={
              <AccountLayout
                user={user}
                logout={logout}
                fetchProfile={fetchProfile}
                statusUpdater={statusUpdater}
              />
            }
          >
            <Route
              exact
              path=""
              element={
                <Suspense fallback={<Loading />}>
                  <Dashboard user={user} statusUpdater={statusUpdater} />
                </Suspense>
              }
            />
            <Route
              exact
              path="profile"
              element={
                <Suspense fallback={<Loading />}>
                  <Profile
                    profile={profile}
                    setProfile={setProfile}
                    user={user}
                    statusUpdater={statusUpdater}
                    fetchProfile={fetchProfile}
                  />
                </Suspense>
              }
            />
            <Route
              exact
              path="payment"
              element={
                <Suspense fallback={<Loading />}>
                  <Payment
                    user={user}
                    name={profile.name}
                    statusUpdater={statusUpdater}
                  />
                </Suspense>
              }
            />
            <Route
              exact
              path="sample"
              element={
                <Suspense fallback={<Loading />}>
                  <SampleLayout
                    user={user}
                    category={profile.category}
                    sampleLoaded={sampleLoaded}
                  />
                </Suspense>
              }
            >
              <Route
                path=":year/:set/:type"
                element={
                  <Suspense fallback={<Loading />}>
                    <SamplePaper
                      sampleLoaded={sampleLoaded}
                      setSampleLoaded={setSampleLoaded}
                    />
                  </Suspense>
                }
              />
            </Route>
            {/* <Route
              exact
              path="query/*"
              element={
                <Suspense fallback={<Loading />}>
                  <Query />
                </Suspense>
              }
            /> */}
            <Route
              exact
              path="settings"
              element={
                <Suspense fallback={<Loading />}>
                  <Settings
                    img={user?.imgUrl}
                    logout={logout}
                    hasPassword={user?.hasPassword}
                    hasGoogleId={user?.hasGoogleId}
                    statusUpdater={statusUpdater}
                  />
                </Suspense>
              }
            />
          </Route>
        </Route>
        <Route
          path="login"
          element={
            <Suspense fallback={<Loading />}>
              <Login user={user} resetAuth={resetAuth} />
            </Suspense>
          }
        />
        <Route
          path="forgotpassword"
          element={
            <Suspense fallback={<Loading />}>
              <ForgotPassword />
            </Suspense>
          }
        />
        <Route
          path="resetpassword"
          element={
            <Suspense fallback={<Loading />}>
              <ResetPassword />
            </Suspense>
          }
        />
        <Route
          exact
          path="register"
          element={
            <Suspense fallback={<Loading />}>
              <Register />
            </Suspense>
          }
        />
        <Route
          exact
          path="terms"
          element={
            <Suspense fallback={<Loading />}>
              <Terms />
            </Suspense>
          }
        />
        <Route
          exact
          path="privacy"
          element={
            <Suspense fallback={<Loading />}>
              <Privacy />
            </Suspense>
          }
        />
        <Route
          exact
          path="refund"
          element={
            <Suspense fallback={<Loading />}>
              <Refund />
            </Suspense>
          }
        />
        <Route
          exact
          path="verify-email/:encryptedId/:code"
          element={
            <Suspense fallback={<Loading />}>
              <EmailVerify />
            </Suspense>
          }
        />
      </Routes>
    </Router>
  )
}
